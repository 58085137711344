<template>
    <div>
      
      <b-row>
        <b-col cols="12" md="7" class="d-flex align-items-center justify-content-start mb-1 mb-md-0"></b-col>
    
        <b-col cols="12" md="5">
          <div class="d-flex align-items-center justify-content-end">
            <flat-pickr v-model="rangeDate" class="form-control mb-1 mr-1" :config="{ mode: 'range'}" />
            <b-button variant="primary" class="mb-1" @click="exportToExcel()">
              <span class="text-nowrap">Export</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
      <!-- table -->
      <b-table ref="selectableTable" :items="items" :fields="fields" responsive striped class="mb-0" @row-selected="onRowSelected">
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
    
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>
    
    
    
          </b-dropdown>
        </template>
        <template #cell(status_number)="data">
          <b-badge :variant="status[1][data.value]">
            {{ status[0][data.value] }}
          </b-badge>
        </template>
        <template #cell(amount)="data">
          <span v-if="data.item.amount">${{(data.item.amount / 100).toFixed(2) }}</span>
        </template>
        <template #cell(net)="data">
          <span v-if="data.item.amount"><b>${{( (data.item.amount / 100 ) * 0.966 - 2.35 ).toFixed(2) }}</b></span>
        </template>
        <template #cell(class_date)="data">
          <span><b>{{ data.item.class_date }}</b> </span>
        </template>
        <template #cell(time)="data">
          <span><b> {{ data.item.start }}-{{ data.item.end }}</b></span>
        </template>
        <template #cell(type_number)="data">
          <b-badge :variant="status[1][data.value]">
            {{ status[0][data.value] }}
          </b-badge>
        </template>
      </b-table>
      <b-row>
        <b-col class="mt-1">
          Total :
          {{ totalRows }}
          , Display Row
          {{ startRow }}
          to
          {{ endRow }}
        </b-col>
        <b-col>
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number prev-class="prev-item" next-class="next-item" align="right" size="md" class="my-0" @change="handleChangePage" />
        </b-col>
      </b-row>
    </div>
    </template>
    
    <script>
    import BCardCode from '@core/components/b-card-code/BCardCode.vue'
    
    import vSelect from 'vue-select'
    import Ripple from 'vue-ripple-directive'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import flatPickr from 'vue-flatpickr-component'
    import moment from 'moment'
    import {
      BTable,
      BAvatar,
      BCardBody,
      BBadge,
      BRow,
      BCol,
      BFormGroup,
      BFormSelect,
      BPagination,
      BInputGroup,
      BFormInput,
      BInputGroupAppend,
      BButton,
      BInputGroupPrepend,
      BModal,
      VBModal
    } from 'bootstrap-vue'
    export default {
      components: {
        moment,
        flatPickr,
        BTable,
        BModal,
        VBModal,
        ToastificationContent,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BInputGroupPrepend,
        BButton,
        BPagination,
        BCardCode,
        BTable,
        BButton,
        BFormGroup,
        BAvatar,
        BCardBody,
        BBadge,
        vSelect,
      },
      directives: {
        Ripple,
      },
      data() {
        return {
          iframe_src: '//cloudfit.pro/invoice.php',
          rangeDate: null,
          searchString: null,
          test_string: null,
          headerTitle: [ 'Class Date','Start','End', 'Class name', 'Tutor', 'Maximum','Waiting','Minimum'],
          headerVal: ['class_date','start','end', 'name', 'tutor', 'maximum','waiting','minimum'],
          genderOptions: [{
              label: 'Male',
              value: 'M'
            },
            {
              label: 'Female',
              value: 'F'
            },
          ],
          planOptions: [{
              label: 'Basic',
              value: 'basic'
            },
            {
              label: 'Company',
              value: 'company'
            },
            {
              label: 'Enterprise',
              value: 'enterprise'
            },
            {
              label: 'Team',
              value: 'team'
            },
          ],
          statusOptions: [{
              label: 'Active',
              value: 'active'
            },
            {
              label: 'Disabled',
              value: 'disabled'
            },
          ],
          centerOptions: [],
          isAddNewMemberSidebarActive: false,
          searchString: null,
          perPage: 10,
          startRow: 1,
          endRow: 10,
          pageOptions: [10, 15, 20],
          totalRows: 1,
          currentPage: 1,
          fields: [{
            key: 'class_date',
            label: 'Class'
          }, 
          {
            key: 'week',
            label: 'WEEK'
          }
         ,
           {
            key: 'time',
            label: 'Time'
          }
         ,
          {
            key: 'name', 
            label: 'Class Name'
          }, {
            key: 'tutor',
            label: 'Tutor'
          }, {
            key: 'maximum',
            label: 'Max'
          }, {
            key: 'waiting',
            label: 'Wait'
          }, {
            key: 'minimum',
            label: 'Min'
          }],
          /* eslint-disable global-require */
          items: [],
          /* eslint-disable global-require */
          status: [{
              1: 'SUCCESSED',
              2: 'FAILED',
              3: 'MANUAL',
              4: 'AUTO'
            },
            {
              1: 'light-success',
              2: 'light-danger',
              3: 'primary',
              4: 'warning'
            }
          ],
          selectMode: 'single',
          selected: [],
        }
      },
      watch: {
        rangeDate: {
          handler(newValue, oldValue) {
            this.get_data()
          },
        },
        currentPage: {
          handler(newValue, oldValue) {
            this.get_data()
          },
        },
        searchString: {
          handler(newValue, oldValue) {
            // console.log("search")
            this.searchString = newValue.toUpperCase()
            this.handleSearch()
          },
        },
        perPage: {
          handler(newValue, oldValue) {
            if (newValue < 9) {
              this.perPage = 10
            }
            // console.log("search")
            this.handleSearch()
          },
        },
      },
      created() {
        this.get_data()
        this.get_center_data()
      },
      methods: {
        retry(id) {
          this.$swal({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, retry it!',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-center',
                props: {
                  title: 'In proccess...',
                  icon: 'XIcon',
                  variant: 'warning',
                  text: '正在進行交易中....',
                },
              })
            }
          })
        },
        exportToExcel() {
          console.log(this.items)
          console.log(this.headerTitle)
          import('@/@core/Export2Excel').then(excel => {
            const list = this.items
            const data = this.formatJson(this.headerVal, list)
            excel.export_json_to_excel({
              header: this.headerTitle,
              data,
              filename: 'Export_report',
              autoWidth: true,
              bookType: 'xlsx'
            })
            //this.clearFields()
          })
        },
        formatJson(filterVal, jsonData) {
          return jsonData.map(v =>
            filterVal.map(j => {
              // Add col name which needs to be translated
              // if (j === 'timestamp') {
              //   return parseTime(v[j])
              // } else {
              //   return v[j]
              // }
    
              return v[j]
            })
          )
        },
        get_data(searchString = null) {
          var splitted = []
          if (this.rangeDate === null) {
            splitted[0] = moment().subtract(30, 'days').format('YYYY-MM-DD')
            splitted[1] = moment().format("YYYY-MM-DD")
            this.rangeDate = moment().subtract(30, 'days').format('YYYY-MM-DD') + " to " + moment().format("YYYY-MM-DD")
          } else {
            splitted = this.rangeDate.split(" to ");
          }
          this.$http.get(process.env.VUE_APP_API_BASE + `/report/class_schedule/?from=` + splitted[0] + `&to=` + splitted[1])
            .then(res => {
              this.items = res.data
              console.log(res.data)
            })
        },
        get_center_data() {
          this.$http.get(process.env.VUE_APP_API_BASE + '/centers/')
            .then(res => {
              this.centerOptions = res.data.centers
            })
        },
        handleChangePage() {
    
          //  this.get_data()
        },
        onRowSelected(items) {
          this.selected = items[0]
          console.log(items)
          if (items[0].is_pdf == 'Y') {
            this.iframe_src = '//www.cloudfit.pro/pdf/INVOICE_' + items[0].code + '.pdf'
            console.log(this.iframe_src)
    
          } else {
            this.iframe_src = process.env.VUE_APP_API_BASE + '/invoice.php?print=n&code=' + items[0].id
          }
          this.transaction_id = items[0].transaction_id
          this.$refs['my-modal'].show()
        },
        close() {
          this.iframe_src = '//www.cloudfit.pro/invoice.php'
          this.$refs['my-modal'].hide()
        },
        selectAllRows() {
          this.$refs.selectableTable.selectAllRows()
        },
        clearSelected() {
          this.$refs.selectableTable.clearSelected()
        },
        selectThirdRow() {
          // Rows are indexed from 0, so the third row is index 2
          this.$refs.selectableTable.selectRow(2)
        },
        unselectThirdRow() {
          // Rows are indexed from 0, so the third row is index 2
          this.$refs.selectableTable.unselectRow(2)
        },
    
        handleSearch() {
          // let _print = `The user searched for: ${searching}\n`
          // this.$refs.pre.appendChild(document.createTextNode(_print))
          // this.searchString = searching
          // this.currentx = 1
          // this.get_data()
          this.searchTimeOut()
        },
        searchTimeOut() {
          if (this.timer) {
            clearTimeout(this.timer)
            this.timer = null
          }
          this.timer = setTimeout(() => {
            // your code
            this.currentPage = 1
            this.get_data(this.searchString)
          }, 500)
        },
      },
    }
    </script>
    <style lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
    </style>
    <style lang="scss">
    .b-table-selectable {
        .feather {
            font-size: 1.3rem;
        }
    }
    </style>
    